import { styled } from "goober";

export const LowSampleWarning = styled("div")`
  & {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--sp-2);
    background-color: var(--primary-15);
    padding: var(--sp-1) var(--sp-3);
    border-radius: var(--br-lg);
    color: var(--primary);
    text-align: center;
    border: 1px solid var(--primary-15);
  }
`;
