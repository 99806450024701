import React from "react";
import { useTranslation } from "react-i18next";

import ChampionBuilds from "@/game-lol/components/ChampionBuilds.jsx";
import ChampionInsights from "@/game-lol/components/ChampionInsights.jsx";
import ChampionMatchups from "@/game-lol/components/ChampionMatchups.jsx";
import SimilarChampions from "@/game-lol/components/SimilarChampions.jsx";
import {
  QUEUE_SYMBOLS,
  ROLE_SYMBOL_TO_STR,
} from "@/game-lol/constants/constants.mjs";
import { patchDisplay } from "@/game-lol/utils/champions-stats-utils.mjs";
import { getQueueDetails } from "@/game-lol/utils/symbol-queue.mjs";
import { getWinRateColor, mapRoleToSymbol } from "@/game-lol/utils/util.mjs";
import ChampionStatsBar from "@/shared/CharacterStatsBar.jsx";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";

const ChampionOverview = ({
  championId,
  champion,
  urlParams = "",
  championStats,
  filters,
  patch,
}) => {
  const { t } = useTranslation();

  const aram = getQueueDetails(QUEUE_SYMBOLS.aram).gql;

  const roleText =
    filters.role &&
    ROLE_SYMBOL_TO_STR[mapRoleToSymbol(filters.role)]?.label &&
    t(...ROLE_SYMBOL_TO_STR[mapRoleToSymbol(filters.role)].label);

  const renderedStats = championStats
    ? [
        {
          key: "winrate",
          title: ["lol:winRate", "Win Rate"],
          value: championStats.win_rate,
          color: getWinRateColor(championStats.win_rate * 100),
          format: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            style: "percent",
          },
        },
        {
          key: "pickrate",
          title: ["lol:pickRate", "Pick Rate"],
          value: championStats.champion_role_pickrate,
          color: "",
          format: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            style: "percent",
          },
        },
        {
          key: "banrate",
          title: ["lol:banRate", "Ban Rate"],
          value: championStats.champion_ban_rate,
          color: "",
          format: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            style: "percent",
          },
        },
        {
          key: "matches",
          title: ["common:matches", "Matches"],
          value: championStats.games,
          color: "",
          format: {},
        },
      ]
    : [];

  if (championStats?.champion_role_tier) {
    const Icon = getTierIcon(championStats.champion_role_tier);
    renderedStats.unshift({
      key: "tier",
      title: ["common:tier", "Tier"],
      value: championStats.champion_role_tier,
      color: "",
      format: {},
      icon: <img src={Icon} width="36" height="36" />,
    });
  }

  return (
    <>
      <ChampionStatsBar stats={renderedStats} loading={!championStats} />
      <ChampionBuilds
        champion={champion}
        championId={championId}
        championRole={filters.role}
        queue={filters.queue}
        region={filters.region}
      />
      {filters.queue !== aram && (
        <ChampionMatchups
          limit={5}
          championId={championId}
          urlParams={urlParams}
          champion={champion}
          title={
            champion?.name
              ? t(
                  "lol:championNamesCountersForRolePatch",
                  "{{championName}}'s Counters for {{role}}, Platinum+ for Patch {{patch}}",
                  {
                    championName: champion.name,
                    role: roleText,
                    patch: patchDisplay(patch, true),
                  },
                )
              : t("common:counters", "Counters")
          }
          subtitle={
            champion?.name
              ? t(
                  "lol:championNameCountersSubtitle",
                  "Discover the ultimate counters for {{championName}} with our comprehensive guide. Learn who counters {{championName}} in lane and throughout the game, turning the tides in your favor. Explore effective {{championName}} counters and strategies to outplay your opponent.",
                  {
                    championName: champion.name,
                  },
                )
              : t(
                  "lol:countersSubtitle",
                  "Discover the ultimate counters for this champion with our comprehensive guide. Learn who counters them in lane and throughout the game, turning the tides in your favor. Explore effective counters and strategies to outplay your opponent.",
                )
          }
        />
      )}
      <ChampionInsights
        championId={championId}
        champion={champion}
        role={filters.role}
        patch={patch}
      />
      <SimilarChampions championId={championId} champion={champion} />
    </>
  );
};

export default React.memo(ChampionOverview);
