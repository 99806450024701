import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Select } from "clutch/src/Select/Select.jsx";
import { ToggleSwitch } from "clutch/src/ToggleSwitch/ToggleSwitch.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { View } from "@/game-lol/components/CommonComponents.jsx";
import RolesButtons from "@/game-lol/components/RolesButtons.jsx";
import {
  FILTER_SYMBOLS,
  RANK_SYMBOL_TO_STR,
  REGION_LIST,
} from "@/game-lol/constants/constants.mjs";
import { patchDisplay } from "@/game-lol/utils/champions-stats-utils.mjs";
import getRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import getRegionIcon from "@/game-lol/utils/get-region-icon.mjs";
import Static from "@/game-lol/utils/static.mjs";
import {
  getQueueFilters,
  QUEUE_SYMBOL_TO_OBJECT,
} from "@/game-lol/utils/symbol-queue.mjs";
import { getStaticChampionById } from "@/game-lol/utils/util.mjs";
import FilterBar from "@/shared/FilterBar.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TEAMS = [
  {
    key: -1,
    t: {
      name: "lol:filters.probuilds.prosAndStreamers",
      fallback: "Pros & Streamers",
    },
  },
  {
    key: 0,
    t: {
      name: "lol:filters.probuilds.pros",
      fallback: "Pros",
    },
  },
  {
    key: 67,
    t: {
      name: "lol:filters.probuilds.streamers",
      fallback: "Streamers",
    },
  },
];

const FILTER_VISIBILITY = {
  default: {
    role: true,
    region: true,
    matchup: true,
    rank: false,
    queue: false,
    victoryOnly: false,
  },
  build: {
    rank: true,
    queue: true,
  },
  aram: {
    role: false,
    queue: false,
    matchup: false,
  },
  arena: {
    role: false,
    queue: false,
    matchup: false,
  },
  probuilds: {
    // team: true,
    matchup: false,
    region: false,
    victoryOnly: true,
  },
  counters: {
    rank: true,
    queue: true,
  },
  synergies: {
    role: true,
    duoRole: true,
    rank: true,
    queue: true,
  },
};

const RightView = styled(View)`
  margin-left: auto;
`;

const StyledFilterBar = styled(FilterBar)`
  position: sticky;
  z-index: 10;
  width: 100%;
  max-width: var(--sp-container);
  border-radius: var(--br, 5px);
`;

function getFilterItemCount(filterVisibility) {
  let itemCount = 0;
  // role
  if (filterVisibility.role) itemCount++;
  // matchup
  if (filterVisibility.matchup) itemCount++;
  // tier
  if (filterVisibility.rank) itemCount++;
  // queue
  if (filterVisibility.queue) itemCount++;
  // region
  if (filterVisibility.region) itemCount++;
  // team
  if (filterVisibility.team) itemCount++;
  // victoryOnly
  if (filterVisibility.victoryOnly) itemCount++;

  return itemCount;
}

function ChampionFilter(props) {
  const {
    champion,
    tab,
    role,
    duoRole,
    tier,
    queue,
    region,
    team,
    victoryOnly,
    matchupChampion,
    setFilter,
  } = props;
  const { t } = useTranslation();

  const filterVisibility = {
    ...FILTER_VISIBILITY["default"],
    ...FILTER_VISIBILITY[tab],
  };

  if (["HOWLING_ABYSS_ARAM", "SUMMONERS_RIFT_URF"].includes(queue)) {
    filterVisibility.role = false;
  }

  const allQueues = getQueueFilters(t, {
    filterFn: (symbol) => !QUEUE_SYMBOL_TO_OBJECT[symbol]?.hideFilter,
  });

  const regions = REGION_LIST.map((v) => {
    const RegionIcon = getRegionIcon(v.key);
    return {
      value: v.datalake,
      text: [v.t.name, v.t.fallback],
      icon: RegionIcon && <RegionIcon />,
    };
  });

  const tiers = Object.getOwnPropertySymbols(RANK_SYMBOL_TO_STR)
    .filter((key) => !RANK_SYMBOL_TO_STR[key]?.hideFilter)
    .map((key) => {
      const tierData = RANK_SYMBOL_TO_STR[key];
      const TierIcon = getRankIcon(tierData.key);
      return {
        value: tierData.gql,
        text: [tierData.t.name, tierData.t.fallback],
        icon: TierIcon && <TierIcon />,
      };
    });

  const teams = TEAMS.map((p) => ({
    value: p.key,
    text: [p.t.name, p.t.fallback],
  }));

  // const queues = useMemo(() => {
  //   return allQueues.slice();
  // }, [allQueues]);

  const matchups = useMemo(() => {
    const list = champion?.stats?.matchups;
    if (!list) return [];
    return list
      .map((m) => {
        const opponent = getStaticChampionById(m.opponentChampionId);
        if (!opponent) return null;
        return {
          name: opponent?.name || m.name,
          image: Static.getChampionImage(m.opponentChampionId),
          value: opponent?.key,
          text: ["lol:championName", opponent?.name],
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(Boolean);
  }, [champion]);

  const missingMatchup =
    matchupChampion && !matchups.find((m) => m.value === matchupChampion.key)
      ? {
          name: matchupChampion.name,
          image: Static.getChampionImage(matchupChampion.id),
          value: matchupChampion.key,
          text: ["lol:championName", matchupChampion.name],
        }
      : null;

  const hiddenItems = getFilterItemCount(filterVisibility) - 1;

  if (!allQueues.map((q) => q.value).includes(queue)) {
    return null;
  }

  return (
    <StyledFilterBar hiddenItems={hiddenItems}>
      {filterVisibility.role && (
        <RolesButtons
          links
          selected={role}
          onClick={(v) => {
            setFilter(FILTER_SYMBOLS.role, v);
          }}
        />
      )}
      {filterVisibility.duoRole && (
        <div
          style={{ display: "flex", gap: "var(--sp-2)", alignItems: "center" }}
        >
          <span className="type-caption--semi shade2">
            {t("common:with", "With")}
          </span>
          <RolesButtons
            links
            selected={duoRole}
            exclude={role}
            onClick={(v) => {
              setFilter(FILTER_SYMBOLS.duoRole, v);
            }}
          />
        </div>
      )}
      {filterVisibility.matchup && matchups.length ? (
        <Select
          selected={matchupChampion?.key}
          options={[
            {
              value: null,
              text: matchupChampion?.key
                ? ["lol:championsPage.removeMatchup", "Remove Matchup"]
                : ["lol:championsPage.addMatchup", "Add a Matchup"],
            },
            missingMatchup,
            ...matchups,
          ]}
          onChange={(v) => setFilter(FILTER_SYMBOLS.matchup, v)}
          inputStyle
        />
      ) : null}
      {filterVisibility.rank && (
        <Select
          selected={tier}
          options={tiers}
          onChange={(v) => setFilter(FILTER_SYMBOLS.tier, v)}
          inputStyle
        />
      )}
      {/* {filterVisibility.queue && (
        <Select
          selected={queue}
          options={queues}
          onChange={(v) => setFilter(FILTER_SYMBOLS.queue, v)}
          inputStyle
        />
      )} */}
      {filterVisibility.region && (
        <Select
          selected={region}
          options={regions}
          onChange={(v) => setFilter(FILTER_SYMBOLS.region, v)}
          inputStyle
        />
      )}
      {filterVisibility.team && (
        <Select
          selected={parseInt(team)}
          options={teams}
          onChange={(v) => setFilter(FILTER_SYMBOLS.team, v)}
          inputStyle
        />
      )}
      {filterVisibility.victoryOnly && (
        <RightView>
          <ToggleSwitch
            value={victoryOnly}
            labelText={t("lol:victoryOnly", "Victory Only")}
            onChange={(v) => setFilter(FILTER_SYMBOLS.victoryOnly, v)}
          />
        </RightView>
      )}
      <StatsPatch tab={tab} />
    </StyledFilterBar>
  );
}

export default ChampionFilter;

function StatsPatch({ tab }) {
  const { t } = useTranslation();
  const {
    lol: { championStatsPatch, championMatchupsPatch, championSynergiesPatch },
  } = useSnapshot(readState);

  const patch =
    tab === "synergies"
      ? championSynergiesPatch
      : tab === "matchups"
        ? championMatchupsPatch
        : !tab || tab === "build" || tab === "aram"
          ? championStatsPatch
          : "";

  if (!patch) return null;

  return (
    <span className="type-caption--semi shade2">
      {t("lol:patchVersion", `Patch {{version}}`, {
        version: patchDisplay(patch, true),
      })}
    </span>
  );
}
