import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import ChampionMatchupImg from "@/game-lol/components/ChampionMatchupImg.jsx";
import { LowSampleWarning } from "@/game-lol/components/ChampionSynergies.style.jsx";
import type { ChampionSynergies as ChampionSynergiesModel } from "@/game-lol/models/lol-champion-synergies.mts";
import lolRefs from "@/game-lol/refs.mjs";
import { getStaticData, getWinRateColor } from "@/game-lol/utils/util.mjs";
import Warning from "@/inline-assets/warning-hand.svg";
import DataTable from "@/shared/DataTable.jsx";
import getTierIconPath from "@/shared/get-tier-icon-path.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function ChampionSynergies({
  championId,
  urlParams,
}: {
  championId: number;
  urlParams: string;
}) {
  const { t } = useTranslation();
  const {
    lol: { championSynergies },
  } = useSnapshot(readState);
  const champions = getStaticData("champions");

  const data: ChampionSynergiesModel =
    championSynergies[championId]?.[urlParams];

  const warn = data && data.length <= 10;

  return (
    <>
      {warn ? (
        <LowSampleWarning>
          <Warning width="32" height="32" />
          <span className="type-subtitle--bold">
            {t(
              "common:stats.lowSampleNewPatch",
              "Low sample size due to new patch",
            )}
          </span>
        </LowSampleWarning>
      ) : null}
      <DataTable
        cols={[
          {
            display: t("lol:champion", "Champion"),
            align: "left",
            primary: true,
          },
          {
            display: t("common:tier", "Tier"),
            align: "center",
          },
          {
            display: t("common:stats.winRate", "Win Rate"),
            isStat: true,
          },
          {
            display: t("common:stats.winRateDelta", "Win Rate delta"),
            isStat: true,
          },
          {
            display: t("common:wins", "Wins"),
            isStat: true,
          },
          {
            display: t("common:matches", "Matches"),
            isStat: true,
          },
        ]}
        rows={data
          ?.sort(
            (a, b) =>
              a.synergy_tier - b.synergy_tier || b.win_rate - a.win_rate,
          )
          .map((duo) => {
            const championKey = champions?.keys?.[duo.ally_champion_id];
            const champion = champions?.[championKey];
            const championName = champion?.name;
            const TierIcon =
              duo.synergy_tier && getTierIconPath(duo.synergy_tier);
            const winRateColor = getWinRateColor(duo.win_rate * 100);
            return [
              {
                display: (
                  <>
                    <ChampionMatchupImg
                      championId={duo.ally_champion_id}
                      winRateColor={winRateColor}
                    />
                    <span className="name-string">
                      {championName || duo.ally_champion_id}
                    </span>
                  </>
                ),
                value: championName || duo.ally_champion_id,
                link: `/${lolRefs.lolChampionPrefix}/${championKey}`,
                style: {
                  gap: "var(--sp-3)",
                },
              },
              {
                display: <img src={TierIcon} width="28" height="28" />,
                value: duo.synergy_tier * -1,
              },
              {
                display: duo.win_rate.toLocaleString(getLocale(), {
                  style: "percent",
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                }),
                value: duo.win_rate,
              },
              {
                display: duo.win_rate_delta.toLocaleString(getLocale(), {
                  style: "percent",
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                }),
                value: duo.win_rate_delta,
              },
              {
                display: duo.wins.toLocaleString(getLocale()),
                value: duo.wins,
              },
              {
                display: duo.games.toLocaleString(getLocale()),
                value: duo.games,
              },
            ];
          })}
        indexCol
        // classNameOuter={AugmentTable()}
        sortCol={1} // Tier
        sortColTiebreak={2} // Pick Rate
        sortDir="DESC"
        searchCol={0}
      />
    </>
  );
}

export default ChampionSynergies;
